// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-jp-about-js": () => import("./../../../src/pages/jp/about.js" /* webpackChunkName: "component---src-pages-jp-about-js" */),
  "component---src-pages-jp-contact-js": () => import("./../../../src/pages/jp/contact.js" /* webpackChunkName: "component---src-pages-jp-contact-js" */),
  "component---src-pages-jp-contact-thanks-js": () => import("./../../../src/pages/jp/contact-thanks.js" /* webpackChunkName: "component---src-pages-jp-contact-thanks-js" */),
  "component---src-pages-jp-education-js": () => import("./../../../src/pages/jp/education.js" /* webpackChunkName: "component---src-pages-jp-education-js" */),
  "component---src-pages-jp-education-product-microbit-basic-js": () => import("./../../../src/pages/jp/education/product/microbit_basic.js" /* webpackChunkName: "component---src-pages-jp-education-product-microbit-basic-js" */),
  "component---src-pages-jp-faq-js": () => import("./../../../src/pages/jp/faq.js" /* webpackChunkName: "component---src-pages-jp-faq-js" */),
  "component---src-pages-jp-index-js": () => import("./../../../src/pages/jp/index.js" /* webpackChunkName: "component---src-pages-jp-index-js" */),
  "component---src-pages-jp-iot-js": () => import("./../../../src/pages/jp/iot.js" /* webpackChunkName: "component---src-pages-jp-iot-js" */),
  "component---src-pages-jp-privacy-policy-js": () => import("./../../../src/pages/jp/privacy-policy.js" /* webpackChunkName: "component---src-pages-jp-privacy-policy-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-cat-template-js": () => import("./../../../src/templates/cat-template.js" /* webpackChunkName: "component---src-templates-cat-template-js" */)
}

